import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import SingleSocila from "../UI/SingleSocila";
import { v4 as uuidv4 } from "uuid";
import RestrictSingleSocial from "../UI/RestrictSingleSocial";

const Socials = ({ edit, setOpenEditPopup }) => {
  const SocialsTranslation = useSelector((state) => state.translation.Socials);

  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  const [socials, setSocials] = useState(collaborator.socials);
  const [restrictSocials, setRestrictSocials] = useState(
    collaborator.backofficeEmptySocials
  );

  // change the list of socials if ther is restriction on the socials
  // useEffect(() => {
  //   if (restriction.restrict_socials) {
  //     setSocials(restriction.socials);
  //   }
  // }, [restriction.restrict_socials]);

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [safe, setSafe] = useState(true);
  const [loading, setSLoading] = useState();
  const [saveIsEmpty, setSaveIsEmpty] = useState(false);

  // ########################################################
  // ######################
  // ############### END DEV AREA

  const handleAddSocial = () => {
    setSocials([
      ...socials,
      {
        _id: uuidv4(),
        url: "",
        social_id: {
          _id: "637235ad60136a9a3e26f78e",
          label: "Linkedin",
          icon: "linkedin.svg",
        },
      },
    ]);
  };

  const onDelete = (index) => {
    const newSocials = socials.filter((item, i) => item._id !== index);
    setSocials(newSocials);
  };

  const handleRestrictChange = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;

    const socialsRestrictChange = [...restrictSocials];

    if (name === "prefix" || name === "phone") {
      const existingUrlParts = socialsRestrictChange[index].url.split("-");
      const prefix = name === "prefix" ? value : existingUrlParts[0];
      const phone = name === "phone" ? value : existingUrlParts[1];

      socialsRestrictChange[index] = {
        ...socialsRestrictChange[index],
        url: `${prefix || ""}-${phone || ""}`,
      };
    } else {
      socialsRestrictChange[index] = {
        ...socialsRestrictChange[index],
        [name]: value,
      };
    }

    setRestrictSocials(socialsRestrictChange);
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;

    const socialsChange = [...socials];

    if (name === "prefix" || name === "phone") {
      const existingUrlParts = socialsChange[index].url.split("-");
      const prefix = name === "prefix" ? value : existingUrlParts[0];
      const phone = name === "phone" ? value : existingUrlParts[1];

      socialsChange[index] = {
        ...socialsChange[index],
        url: `${prefix || ""}-${phone || ""}`,
      };
    } else {
      socialsChange[index] = {
        ...socialsChange[index],
        [name]: value,
      };
    }

    setSocials(socialsChange);
  };

  const handleSubmit = () => {
    if (restriction.restrict_socials) {
      try {
        patchCollaborator({
          id: collaborator._id,
          content: {
            backofficeEmptySocials: restrictSocials,
            ...(edit ? {} : { onboarding_step: 5 }),
          },
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        patchCollaborator({
          id: collaborator._id,
          content: { socials, ...(edit ? {} : { onboarding_step: 5 }) },
        }).unwrap();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <section className="pt-[7vh] mx-5 pb-[120px]">
      <div className="font-SteradianMedium text-3xl">
        {SocialsTranslation.title[lang]}
      </div>
      <div className="text-lg mt-5">{SocialsTranslation.subtitle[lang]}</div>
      <div className="my-10">
        {restriction.restrict_socials &&
          restriction.socials
            .filter((social) => social.url.length > 0)
            .map((social, i) => (
              <div>
                <div className="border-[0.7px] border-inputBottom bg-labelsBg h-11 w-[122px] flex items-center justify-between px-[9px] rounded-lg">
                  <span>{social?.social_id?.label}</span>
                  <img
                    className="w-[15px] h-[15px]"
                    src="../../assets/img/icons/lock.svg"
                    alt="show option"
                  />
                </div>
                <div className="my-[15px]">{social?.url}</div>
              </div>
            ))}
        {collaborator.backofficeEmptySocials.length > 0 &&
          restriction.restrict_socials &&
          collaborator.backofficeEmptySocials.map((social, i) => (
            <RestrictSingleSocial
              index={i}
              social={social}
              handleRestrictChange={handleRestrictChange}
            />
          ))}

        {!restriction.restrict_socials &&
          socials.map((social, i) => (
            <SingleSocila
              social={social}
              socials={socials}
              setSocials={setSocials}
              key={social._id}
              onDelete={onDelete}
              handleChange={handleChange}
              restrictSocial={restriction.restrict_socials}
              index={i}
            />
          ))}

        {/* {socials.map((social, i) =>
          restriction.restrict_socials ? (
            // <div className="mb-4">
            //   <div className="border-[0.7px] border-inputBottom bg-labelsBg h-11 w-[122px] flex items-center justify-between px-[9px] rounded-lg">
            //     <span>{social?.social_id?.label}</span>
            //     <img
            //       className="w-[15px] h-[15px]"
            //       src="../../assets/img/icons/lock.svg"
            //       alt="show option"
            //     />
            //   </div>
            //   {social.url ? (
            //     <div className="my-[15px]">{social?.url}</div>
            //   ) : (
            //     <input
            //       name="url"
            //       value={social.url}
            //       placeholder="www"
            //       className="block bg-transparent w-full text-lg border-b border-inputBottom focus:outline-none"
            //       onChange={(e) => handleChange(e, i)}
            //     />
            //   )}
            //   {/* <div className="my-[15px]">{social?.url}</div> */}
        {/* // </div>
            <RestrictSingleSocial
              index={i}
              social={social}
              handleRestrictChange={handleRestrictChange}
            />
          ) : (
            <SingleSocila
              social={social}
              socials={socials}
              setSocials={setSocials}
              key={social._id}
              onDelete={onDelete}
              handleChange={handleChange}
              restrictSocial={restriction.restrict_socials}
              index={i}
            />
          )
        )} */}
      </div>

      {!restriction.restrict_socials && (
        <div
          className="flex gap-4 text-labels font-SteradianMedium text-lg cursor-pointer"
          onClick={handleAddSocial}
        >
          <img src="../../assets/img/icons/plus-circle.svg" alt="Add socila" />
          {SocialsTranslation.button?.[lang]}
        </div>
      )}

      <div className="button-container">
        <button
          className={safe ? "btn-fields btn-active" : "btn-fields"}
          id="btn-next"
          onClick={
            edit
              ? (e) => {
                  handleSubmit(e);
                  setOpenEditPopup(false);
                }
              : handleSubmit
          }
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
            ? SocialsTranslation?.buttonSave[lang]
            : SocialsTranslation?.buttonNext[lang]}
        </button>
      </div>
    </section>
  );
};

export default Socials;
