import React, { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClosePopup from "../../hooks/useOutsideClosePopup";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "../../redux/appSlices/collaborator/collaboratorSlice";

const LanguageSelect = () => {
  const dispatch = useDispatch();

  const options = [
    { value: "fr", label: "FR", icon: "/assets/img/icons/fr-flag.svg" },
    { value: "en", label: "EN", icon: "/assets/img/icons/en-flag.svg" },
  ];

  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";

  const [selectedValue, setSelectedValue] = useState();
  const [openOption, setOpenOption] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClosePopup(wrapperRef, setOpenOption);

  useEffect(() => {
    setSelectedValue(options.findIndex((option) => option.value === lang));
  }, [lang]);

  useEffect(() => {
    if (lang) {
      dispatch(setLang(lang));
    } else {
      dispatch(setLang("en"));
    }
  }, [lang, collabLang, teamLang]);

  return (
    <div ref={wrapperRef} className="ml-auto">
      <button
        className="bg-white bg-opacity-50 text-base flex justify-around ml-auto border border-white rounded-[0.25rem] items-center w-16 h-8"
        onClick={() => {
          setOpenOption(!openOption);
        }}
      >
        <img
          src={options?.[selectedValue]?.icon}
          className="h-[14px] w-[14px]"
        />
        <span>{options?.[selectedValue]?.label}</span>
        <img
          className={openOption ? "rotate-180" : ""}
          src="/assets/img/icons/down-filled.svg"
        />
      </button>
      {openOption &&
        options
          .filter((option) => option.value !== lang)
          .map((opt) => (
            <button
              className="bg-white bg-opacity-50 text-base flex justify-around ml-auto border border-white rounded-[0.25rem] items-center w-16 h-8 mt-1"
              onClick={() => {
                setOpenOption(false);
                setSelectedValue(
                  options.findIndex((option) => option.value === opt.value)
                );
                dispatch(setLang(opt.value));
              }}
            >
              <img src={opt?.icon} className="h-[14px] w-[14px]" />
              <span>{opt?.label}</span>
            </button>
          ))}
    </div>
  );
};

export default LanguageSelect;
