import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/onboarding/onboardingApiSlice";
import { setOnboardingStep } from "../../redux/appSlices/collaborator/collaboratorSlice";
import ListImage from "../UI/ListImage";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  useDeleteCollaboratorImageMutation,
  useUploadCollaboratorImageMutation,
} from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";

const Images = ({ edit, setOpenEditPopup }) => {
  const dispatch = useDispatch();
  const imageTranslation = useSelector((state) => state.translation.Images);
  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  const restriction = useCollaboratorRestriction();

  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();
  const [uploadCollaboratorImage] = useUploadCollaboratorImageMutation();
  const [deleteCollaboratorImage] = useDeleteCollaboratorImageMutation();

  const [images, setImages] = useState(collaborator.images);

  useEffect(() => {
    if (restriction?.restrict_images) {
      setImages(restriction?.images);
    } else {
      setImages(collaborator.images);
    }
  }, [restriction, collaborator]);

  // ############### DEV AREA
  // ######################
  // ########################################################

  const [safe, setSafe] = useState(true);
  const [loading, setLoading] = useState();

  const handleDelete = (imagePath) => {
    // console.log(`Deleting image: ${imagePath}`);
    deleteCollaboratorImage({ image: `${imagePath}` });
  };

  const handleSubmit = () => {
    try {
      patchCollaborator({
        id: collaborator._id,
        content: { images, ...(edit ? {} : { onboarding_step: 9 }) },
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  // ########################################################
  // ######################
  // ############### END DEV AREA

  const handleImageUpload = (e) => {
    uploadCollaboratorImage(e.target.files[0]);
  };

  return (
    <section className="pt-[7vh] mx-5 pb-[120px]">
      <div>
        <div className="text-2xl font-SteradianMedium">
          {imageTranslation.title[lang]}
        </div>
        <div className="text-lg mt-5">{imageTranslation.subtitle[lang]}</div>
        {!restriction.restrict_images && (
          <div>
            <label
              htmlFor="uploadPicture"
              className="w-full h-40 border-dashed border items-center justify-center border-labels bg-labelsBg flex flex-col rounded-xl mt-5 text-labels cursor-pointer"
            >
              {" "}
              <span className="block">Upload a picture</span>{" "}
              <AiOutlinePlusCircle />
            </label>
            <input
              className="hidden"
              id="uploadPicture"
              type="file"
              name=""
              onChange={handleImageUpload}
            />
          </div>
        )}
        <div className="grid grid-cols-3 gap-10 mt-7 items-center justify-center justify-items-center">
          {images.map((image) => (
            <ListImage
              key={image._id}
              image={image}
              lang={lang}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      </div>
      <div className="button-container">
        <button
          className={safe ? "btn-fields btn-active" : "btn-fields"}
          id="btn-next"
          onClick={
            edit
              ? (e) => {
                  handleSubmit(e);
                  setOpenEditPopup(false);
                }
              : handleSubmit
          }
          disabled={!safe}
        >
          {edit ? (
            <img
              src="/assets/img/loaders/loading.gif"
              style={{
                display: loading ? undefined : "none",
                padding: "2px",
              }}
              width="23px"
              alt=""
              className="ml-[45%]"
            />
          ) : null}
          {loading
            ? null
            : edit
            ? imageTranslation?.buttonSave[lang]
            : imageTranslation?.buttonNext[lang]}
        </button>
      </div>
    </section>
  );
};

export default Images;
