import React, { useState } from "react";
import OrderElements from "./OrderElemets";
import { useSelector } from "react-redux";
import { usePatchCollaboratorMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import { translation } from "../../translation";

const OrderSections = ({ setOpenEditPopup }) => {
  const [patchCollaborator, { isLoading }] = usePatchCollaboratorMutation();

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";
  const [newOrder, setNewOrder] = useState(collaborator.element_order);

  // console.log(newOrder);

  const defaultOrder = [
    "À propos",
    "Coordonnées",
    "Réseaux sociaux",
    "Produits et services",
    "Prendre un rendez-vous",
    "Liens et pdfs",
    "Images",
    "Vidéos",
  ];

  const ChangeOrder = (index, step) => {
    const currentOrderChange = swapElements([...newOrder], index, index + step);
    setNewOrder([...currentOrderChange]);
  };

  const handleSubmit = () => {
    patchCollaborator({
      id: collaborator._id,
      content: { element_order: newOrder },
    });
    setOpenEditPopup(false);
  };

  const swapElements = (arr, i1, i2) => {
    [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
    return arr;
  };

  return (
    <section className="pt-[7vh] mx-5">
      <div className="font-SteradianMedium text-2xl">
        {translation?.orderSection?.title?.[lang]}
      </div>
      <div className="py-5 text-lg">
        {translation?.orderSection?.subtitle?.[lang]}
      </div>
      <div className="p-3 mt-5">
        {newOrder.map((val, i) => {
          return (
            <OrderElements
              text={defaultOrder[val - 1]}
              index={i}
              ChangeOrder={ChangeOrder}
            />
          );
        })}
      </div>
      <div className="" style={{ height: "6rem" }} />
      <div className="button-container">
        <button
          className="btn-fields btn-active"
          id="btn-next-arrange"
          style={{ position: "fixed !important", bottom: "5vh !important" }}
          onClick={handleSubmit}
        >
          {translation?.orderSection?.button?.[lang]}
        </button>
      </div>
    </section>
  );
};

export default OrderSections;
