import React, { useState } from "react";
import { useSelector } from "react-redux";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import { translation } from "../../../translation";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import EmptyContent from "../EmptyContent";
import useTracking from "../../../hooks/useTracking";

const ShowCalendly = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const initialEditId = 5;

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();

  let appointment = collaborator?.appointment;
  if (restriction?.restrict_appointment) {
    appointment = restriction?.appointment;
  }

  if (!logged && (!appointment || appointment === "")) {
    return null;
  }

  return (
    <>
      {(restriction?.show_appointment ||
        (!logged && (!appointment || appointment === ""))) && (
        <section className="mt-9">
          <div className="flex justify-between items-center mb-[3vh]">
            <h2 className="text-xl font-SteradianMedium">
              {translation?.sectionTitle?.appointment?.[lang]}
            </h2>
            {logged && (
              <EditButton
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
              />
            )}
          </div>
          {logged &&
          !restriction?.restrict_appointment &&
          (!collaborator?.appointment || collaborator?.appointment === "") ? (
            <EmptyContent
              setOpenEditPopup={setOpenEditPopup}
              initialEditId={initialEditId}
              setComponantToEditId={setComponantToEditId}
              sectionTitle={translation?.sectionTitle?.appointment?.[lang]}
            />
          ) : (
            <div
              className="mt-3 cursor-pointer"
              onClick={() =>
                sendTracking({
                  action: 102,
                  collaborator_id: collaborator.collaborator_id,
                  componant: "Calendly",
                  url: appointment,
                })
              }
            >
              <a
                href={
                  appointment && appointment.startsWith("http")
                    ? appointment
                    : `https://${appointment}`
                }
                className="flex items-center gap-[15px]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center justify-center h-[4.5rem] w-[4.5rem] rounded-md shadow-lg bg-white">
                  <img src="../../assets/img/icons/calendly.svg" />
                </div>
                <p className="w-[70%] text-[18px] tracking-[-.73px] leading-[25px]">
                  {translation?.appointment?.appuyer?.[lang]}{" "}
                  {collaborator?.first_name}
                </p>
                <img
                  className="ml-auto"
                  src="../../assets/img/icons/arrow-right.svg"
                />
              </a>
            </div>
          )}

          {logged && openEditPopup && (
            <EditComponants
              componantId={componantToEditId}
              setOpenEditPopup={setOpenEditPopup}
            />
          )}
        </section>
      )}
    </>
  );
};

export default ShowCalendly;
