import React, { useEffect, useState } from "react";
import MainCard from "./profile/MainCard";
import { translation } from "../translation";
import { useSelector } from "react-redux";

const LogoutAnimation = ({ onHide }) => {
  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";
  const [popup, setPopup] = useState({ transform: "rotate(33deg)" });

  const swiperCard = () => {
    setPopup({
      transform: "none",
    });

    setTimeout(() => {
      setPopup({ transform: "rotate(33deg)" });
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      swiperCard();
    }, 400);
    setInterval(() => {
      swiperCard();
    }, 5000);
  }, []);

  return (
    <div
      className="fixed top-0 h-[100vh] w-full bg-black bg-opacity-90 max-w-[580px] m-auto z-[999]"
      style={{ display: "block" }}
    >
      <div className="mt-[285px] m-5">
        <div className="">
          <MainCard margin={0} infinite_animation={true} />
        </div>
        <div className="flex flex-col text-white mt-[57px] w-full items-center">
          <img
            style={popup}
            src="../../assets/img/icons/hand-swipe.svg"
            id="icon-swipe"
            alt=""
            srcSet=""
            // className="w-[80px] h-[80px]"
          />
          <p className="text-[19px] mt-[21px] text-center w-[286px] tracking-[-.89px]">
            {translation?.logoutAnim?.title?.[lang]}
          </p>
          <button
            className="bg-transparent border rounded-[6px] mt-[30px] py-[10px] px-[40px] cursor-pointer"
            onClick={onHide}
          >
            {translation?.logoutAnim?.button?.[lang]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutAnimation;
