import React, { useEffect, useRef, useState } from "react";
import { translation } from "../../translation";
import { useSelector } from "react-redux";
import { useCheckContactLockMutation } from "../../redux/appSlices/collaborator/collaboratorApiSlice";
import useOutsideClosePopup from "../../hooks/useOutsideClosePopup";

function ContactLock({
  id,
  setOpenFormContactLock,
  contactAccess,
  setContactAccess,
  handleDownload,
}) {
  const inputRefs = useRef([]);
  const [code, setCode] = useState(["", "", "", ""]);
  const [error, setError] = useState(false);

  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";

  const [checkContactLock] = useCheckContactLockMutation();

  const wrapperRef = useRef(null);
  useOutsideClosePopup(wrapperRef, setOpenFormContactLock);

  useEffect(() => {
    if (error) {
      setError(false);
    }
  }, [code]);

  const handleChange = (index, e) => {
    const value = e.target.value;
    setCode((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = value;
      return newArray;
    });

    // Move focus to next input if current input is filled
    if (value !== "" && index < inputRefs.current.length - 1) {
      setTimeout(() => {
        inputRefs.current[index + 1].focus();
      }, 10);
    }

    // Move focus to previous input if current input is empty
    if (value === "" && index > 0) {
      setTimeout(() => {
        inputRefs.current[index - 1].focus();
      }, 10);
    }
  };

  useEffect(() => {
    if (contactAccess) {
      setOpenFormContactLock(false);
      handleDownload();
    }
  }, [contactAccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await checkContactLock({
        id,
        content: {
          password: code.join(""),
        },
      }).unwrap();

      setContactAccess(true);
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div
        ref={wrapperRef}
        className="fixed top-[30%] text-center w-[90%]  flex items-center justify-center bg-white  z-50 p-8 rounded-xl"
      >
        <form onSubmit={handleSubmit}>
          <div className="text-[24px]">
            {translation?.ContactLock?.title?.[lang]}
          </div>
          <div className="mb-3">
            {error ? (
              <p className="text-red-500">
                {translation?.ContactLock?.error?.[lang]}
              </p>
            ) : (
              <p className="text-[14px] text-[#4E3131]">
                {translation?.ContactLock?.subtitle?.[lang]}
              </p>
            )}
          </div>
          <div className="flex gap-2 mb-3 justify-center">
            {[...Array(4)].map((_, index) => (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                className={`h-[45px] w-[40px] pl-[14px] rounded-[9px] border bg-opacity-[.2] ${
                  error ? "bg-[#f81313] !border-[#ff4646]" : "border-[#ccb7b7]"
                }`}
                style={{ borderColor: "#CBD5E0" }}
                value={code[index]}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => {
                  // Move focus to next input on numeric key press
                  if (
                    /^[0-9]$/.test(e.key) &&
                    index < inputRefs.current.length - 1
                  ) {
                    inputRefs.current[index + 1].focus();
                  }
                }}
                ref={(el) => (inputRefs.current[index] = el)}
                disabled={index > 0 && code[index - 1] === ""}
              />
            ))}
          </div>
          <button
            type="submit"
            className="px-4 py-4 rounded-lg w-full bg-black text-white mt-4"
          >
            {translation?.ContactLock?.button?.[lang]}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactLock;
