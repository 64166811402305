import React from "react";
import { translation } from "../../translation";
import { useSelector } from "react-redux";

const EmptyContent = ({
  sectionTitle,
  setOpenEditPopup,
  initialEditId,
  setComponantToEditId,
  isPlural,
}) => {
  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";

  return (
    <div
      onClick={() => {
        setOpenEditPopup(true);
        setComponantToEditId(initialEditId);
      }}
      className="flex flex-col items-center justify-center bg-labelsBg text-labels py-5 rounded-lg border-2 border-dashed border-inputBottom gap-[10px]"
    >
      <h3 className="text-lg tracking-[-0.73px] leading-6">
        {!isPlural
          ? translation?.emptyCompo?.addPlural?.[lang]
          : translation?.emptyCompo?.add?.[lang]}{" "}
        {sectionTitle.toLowerCase()}
      </h3>
      <img src="../../assets/img/icons/plus.svg" alt="add content" />
    </div>
  );
};

export default EmptyContent;
