import React, { useState } from "react";
import { useSelector } from "react-redux";
import useLoggedStatus from "../../../hooks/useLoggedStatus";
import EditButton from "../EditButton";
import EditComponants from "../EditComponants";
import useCollaboratorRestriction from "../../../hooks/useCollaboratorRestriction";
import { translation } from "../../../translation";
import EmptyContent from "../EmptyContent";
import useTracking from "../../../hooks/useTracking";
import useWindowOpen from "../../../hooks/useWindowOpen";

function ShowSocials() {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  let collabLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.language
  );
  let teamLang = useSelector(
    (state) => state.collaborator.collaboratorInfo?.team_id?.language
  );
  let lang = teamLang || collabLang || "en";

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [componantToEditId, setComponantToEditId] = useState(0);
  const initialEditId = 3;

  const logged = useLoggedStatus();
  const restriction = useCollaboratorRestriction();
  const { sendTracking } = useTracking();
  const windowOpen = useWindowOpen;

  let socials = collaborator.socials;
  if (restriction?.restrict_socials) {
    socials = restriction?.socials.filter((social) => social.url.length > 0);
    if (collaborator?.backofficeEmptySocials?.length > 0) {
      socials = [
        ...restriction?.socials.filter((social) => social?.url?.length > 0),
        ...collaborator?.backofficeEmptySocials?.filter(
          (social) => social?.url?.length > 0
        ),
      ];
    }
  }

  if (!logged && !socials?.length > 0) {
    return null;
  }

  const handleTracking = (url, social_id) => {
    sendTracking({
      action: 112,
      collaborator_id: collaborator.collaborator_id,
      componant: "Socials",
      url: url,
      social_id: social_id,
    });
  };

  return (
    <>
      {restriction?.show_socials && (
        <section className="mt-9">
          <div className="flex justify-between items-center mb-[3vh]">
            <h2 className="text-xl font-SteradianMedium">
              {translation?.sectionTitle?.socials?.[lang]}
            </h2>
            {logged && (
              <EditButton
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
              />
            )}
          </div>
          {logged &&
            !restriction?.restrict_socials &&
            collaborator?.socials?.length === 0 && (
              <EmptyContent
                setOpenEditPopup={setOpenEditPopup}
                initialEditId={initialEditId}
                setComponantToEditId={setComponantToEditId}
                sectionTitle={translation?.sectionTitle?.socials?.[lang]}
              />
            )}
          {/* <div className="mt-3 grid grid-cols-4 gap-5 md:flex md:items-center md:justify-between"> */}
          <div className="mt-3 grid grid-cols-4 gap-5 md:grid-cols-6">
            {socials?.map((social) => (
              <div
                className="flex items-center justify-center h-[4.5rem] w-[4.5rem] rounded-md shadow-lg bg-white cursor-pointer"
                onClick={() => {
                  windowOpen(social.url, social.social_id._id);
                  handleTracking(social.url, social.social_id._id);
                }}
                // href={
                //   social.social_id._id === '637235ad60136a9a3e26f784'
                //     ? `https://wa.me/+${social.url.replace("-", "")}`
                //     : (social.url && social.url.startsWith('http') ? social.url : `http://${social?.url}`)
                // }
                // href={social.url && social.url.startsWith('http') ? social.url : `http://${social?.url}`}
                // target="_blank"
                // rel="noopener noreferrer"
                key={social._id}
              >
                <img
                  className="w-10"
                  src={`../assets/img/socials/${social.social_id.icon}`}
                />
              </div>
            ))}
          </div>
          {logged && openEditPopup && (
            <EditComponants
              componantId={componantToEditId}
              setOpenEditPopup={setOpenEditPopup}
            />
          )}
        </section>
      )}
    </>
  );
}

export default ShowSocials;
